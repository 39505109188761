import { AppThunk } from 'redux/store';
import BackendService from 'services/Backend/Backend.service';
import { toast } from 'react-toastify';
import { ProductInventoryActions } from 'reducers/productInventory.reducer';

export const editSKUDetails = ({
  rowIndex,
  currentValue,
  sku,
  key,
}): AppThunk => async (dispatch) => {
  const newValue = !currentValue;

  dispatch(ProductInventoryActions.modifyCheckboxCell({
    value: newValue,
    rowIndex,
    key,
  }));

  try {
    const data = { sku: sku, [key]: newValue };
    await BackendService.editSKUDetails(data);
  } catch (error) {
    toast.error(`Could not edit sku details for sku ${sku}. Please try again.`);
  }
};
