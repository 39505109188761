import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { CSVLink } from 'react-csv';
import { format } from 'date-fns';
import _ from 'lodash';
import { Helmet } from "react-helmet";

import actions from '../actions';
import ArchiveIcon from '@material-ui/icons/Archive';
import { withStyles } from '@material-ui/core/styles';

import moment from 'moment';
import Modal from './../components/Modal/Modal';

import IncomingTable from '../components/ReactTable/IncomingTable.jsx';
import { calculateWOH } from '../helpers/inventory';
import { HTML_TITLE_SUFFIX } from 'shared/constants';
import { compareCaseInsensitive } from 'shared/utils';


const styles = theme => ({
  root: {
    flexGrow: 1,
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex'
  }
});

class Incoming extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      columnSettings: false,
      incomingHeader: [
        { label: 'SKU', key: 'sku', onTable: true },
        { label: 'Artisan', key: 'artisan', onTable: true },
        { label: 'QB Product Name', key: 'title', onTable: true },
        { label: 'Expected Qty', key: 'expected', onTable: true },
        { label: 'Avail for Pre-sell Qty', key: 'available', onTable: true },
        { label: 'Shopify Status', key: 'vendor', onTable: true },
        { label: 'Web Ship Date', key: 'web_ship_date', onTable: true },
        {
          label: 'Earliest Customer Ship Date',
          key: 'customerShipDate',
          onTable: true
        },
        { label: 'Estimated Landing Date', key: 'landingDate', onTable: true },
        { label: 'Artisan Ship Date', key: 'shipDate', onTable: true },
        { label: 'Shipment ID', key: 'shipmentId', onTable: true },
        { label: 'Status', key: 'status', onTable: true },
        { label: 'Tracking #', key: 'tracking', onTable: true },
        { label: 'WOH + OOO', key: 'wohCount', onTable: true },
        { label: 'Acq Cost', key: 'acqCost', onTable: true },

        { label: 'Total Acq Cost', key: 'totalAcqCost', onTable: true },
        { label: 'Budgeted Shipping Cost', key: 'budgetCost', onTable: true },
        {
          label: 'Total Budgeted Shipping Cost',
          key: 'totalBudgetCost',
          onTable: true
        },
        { label: 'Landed Cost', key: 'landingCost', onTable: true },
        { label: 'Total Landed Cost', key: 'totalLandingCost', onTable: true }
      ]
    };

    this.handleToggleColumn = this.handleToggleColumn.bind(this);
    this.closeToggle = this.closeToggle.bind(this);
    this.prepareData = this.prepareData.bind(this);
    this.prepareCSV = this.prepareCSV.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    if (this.props.shopifyProductsData.shopifyProducts.length === 0) {
      this.props.actions.fetchShopifyProductsData();
      this.props.actions.fetchShipHeroData();
    }

    this.props.actions.fetchOrders();
    this.props.actions.fetchShopifyMergeSKU();
    this.props.actions.fetchShipmentAll();
    this.props.actions.fetchShipmentAllItems();
    this.props.actions.fetchIncomingData();
    this.props.actions.fetchAllOrderDetailForIncomingView();
    this.props.actions.getWebShipmentDate();
  }

  closeToggle() {
    this.setState({ columnSettings: false });
  }

  handleToggleColumn() {
    this.setState({ columnSettings: !this.state.columnSettings });
  }

  handleChangeTable(header) {
    console.log(header);
  }

  onSubmit() {
    this.closeToggle();
  }

  prepareCSV(data) {
    let csvData = [
      [
        'SKU',
        'Artisan',
        'QB Product Name',
        'Expected Qty',
        'Avail for Pre-sell Qty',
        'Shopify Status',
        'Web Ship Date',
        'Earliest Customer Ship Date',
        'Estimated Landing Date',
        'Artisan Ship Date',
        'Shipment ID',
        'Status',
        'Tracking #',
        'WOH + OOO',
        'Acq Cost',
        'Total Acq Cost',
        'Budgeted Shipping Cost',
        'Total Budgeted Shipping Cost',
        'Landed Cost',
        'Total Landed Cost'
      ]
    ];

    data.map(r => {
      let web_ship_date = r.web_ship_date;
      let customerShipDate = r.customerShipDate;
      let landingDate = r.landingDate;
      let shipDate = r.shipDate;

      if (customerShipDate !== 'Waitlist') {
        if (customerShipDate !== '') {
          customerShipDate = moment(customerShipDate).format('MM/DD/YYYY');
        }

        if (customerShipDate === 'Invalid date') {
          customerShipDate = '';
        }
      }

      if (r.web_ship_date === 'Waitlist' || !!r.waitlist) {
        web_ship_date = 'WAITLIST';
      } else if (web_ship_date !== '') {
        web_ship_date = moment(web_ship_date).format('MM/DD/YYYY');

        if (web_ship_date === 'Invalid date') {
          web_ship_date = '';
        }
      }

      if (landingDate !== '') {
        landingDate = moment(landingDate).format('MM/DD/YYYY');

        if (landingDate === 'Invalid date') {
          landingDate = '';
        }
      }

      if (shipDate !== '') {
        shipDate = moment(shipDate).format('MM/DD/YYYY');

        if (shipDate === 'Invalid date') {
          shipDate = '';
        }
      }

      csvData.push([
        r.sku,
        r.artisan,
        r.title,
        r.expected,
        r.available,
        r.vendor,
        web_ship_date,
        customerShipDate,
        landingDate,
        shipDate,
        r.shipmentId,
        r.status,
        r.tracking,
        r.wohCount,
        r.acqCost,
        r.totalAcqCost,
        r.budgetCost,
        r.totalBudgetCost,
        r.landingCost,
        r.totalLandingCost
      ]);

      return true;
    });

    return csvData;
  }

  prepareData() {
    const { shopifyProductsData, shiphero, order, shipment } = this.props;
    const result = [];

    shipment.shipmentAllItems.map(record => {
      let shopifySKU = record.sku;
      let oldSku = '';
      let preparedData = {
        acqCost: null,
        available: record.available,
        budgetCost: null,
        customerShipDate: record.waitlist ? 'Waitlist' : null,
        expected: record.expected,
        inventory_quantity: null,
        landingCost: null,
        landingDate: null,
        outstandingAll: null,
        shipDate: null,
        shipmentId: null,
        shipmentItemId: record.id,
        sku: record.sku,
        title: null,
        totalAcqCost: null,
        totalBudgetCost: null,
        totalLandingCost: null,
        tracking: null,
        sell_thru: null,
        status: null,
        artisan: null,
        vendor: null,
        web_ship_date: null,
        wohCount: null
      };

      let mergedData = _.find(shopifyProductsData.shopifyMerge, product =>
        compareCaseInsensitive(product.newSKU, record.sku)
      );
      if (typeof mergedData != 'undefined') {
        oldSku = mergedData.SKU;
        shopifySKU = mergedData.SKU;
      }

      let currentShipment = _.find(shipment.shipmentAll, {
        id: record.shipmentId
      });

      if (typeof currentShipment !== 'undefined') {
        preparedData = {
          ...preparedData,
          customerShipDate: record.waitlist
            ? 'Waitlist'
            : currentShipment.customerShipDate,
          shipmentId: currentShipment.shipmentId,
          shipDate: currentShipment.shipDate,
          landingDate: currentShipment.landingDate,
          tracking: currentShipment.tracking,
          status: currentShipment.status
        };
      }

      let bill = _.find(shipmentBills, { PurchaseOrderId: preparedData.shipmentId });
      if (typeof bill === 'undefined') {
        let currentOrder = _.find(order.ordersData, {
          PurchaseOrderId: record.orderId.toString()
        });
        if (typeof currentOrder !== 'undefined') {
          preparedData = {
            ...preparedData,
            artisan: currentOrder.artisan
          };
        }

        if (preparedData.shipmentId !== null) {
          let shipmentId = preparedData.shipmentId.split('-')[0];
          let currentOrderDetails = _.find(order.orderAllDetailsData, (orderDetail) => {
            return compareCaseInsensitive(orderDetail.sku,record.sku) &&
              orderDetail.purchase_order_id === shipmentId;
          });
          if (typeof currentOrderDetails !== 'undefined') {
            preparedData = {
              ...preparedData,
              title: currentOrderDetails.product_name,
              acqCost: currentOrderDetails.acquisition_cost,
              totalAcqCost: (
                currentOrderDetails.acquisition_cost * record.expected
              ).toFixed(2)
            };
          }
        }

        let currentShopify = _.find(shopifyProductsData.shopifyProducts, product =>
          compareCaseInsensitive(product.sku, shopifySKU)
        );
        if (typeof currentShopify !== 'undefined') {
          preparedData.vendor = currentShopify.vendor;
        }

        let currentShopifyProduct = _.find(shopifyProductsData.shopifyShipDate, product =>
          compareCaseInsensitive(product.sku, record.sku)
        );
        let oldShopifyProduct = undefined;
        if (oldSku !== '') {
          oldShopifyProduct = _.find(shopifyProductsData.shopifyShipDate, product =>
            compareCaseInsensitive(product.sku, oldSku)
          );
        }

        let currentShiphero = _.find(shiphero.processedData, product =>
          compareCaseInsensitive(product.sku, preparedData.sku)
        );

        if (typeof currentShopifyProduct !== 'undefined') {
          preparedData.sell_thru = currentShopifyProduct.sell_thru;
          preparedData.web_ship_date = currentShopifyProduct.waitlist
            ? 'Waitlist'
            : currentShopifyProduct.shipDate;

          if (currentShiphero) {
            preparedData.wohCount = calculateWOH(
              record.sku,
              currentShopifyProduct.sell_thru,
              currentShiphero.available,
              currentShiphero.backorder,
              order.orderAllDetailsData
            );
          }

          if (typeof oldShopifyProduct === 'undefined') {
            preparedData.waitlist = currentShopifyProduct.waitlist;
          }
        }

        if (typeof oldShopifyProduct !== 'undefined') {
          preparedData.web_ship_date = oldShopifyProduct.waitlist
            ? 'Waitlist'
            : oldShopifyProduct.shipDate;
          preparedData.waitlist = oldShopifyProduct.waitlist;
        }

        result.push(preparedData);
      }

      return true;
    });

    return result.sort((a, b) => {
      let aValue = a.shipmentId.split('-');
      let bValue = b.shipmentId.split('-');

      if (parseInt(aValue[0]) - parseInt(bValue[0]) === 0) {
        return parseInt(aValue[1]) - parseInt(bValue[1]);
      } else {
        return parseInt(aValue[0]) - parseInt(bValue[0]);
      }
    });
  }

  render() {
    const { classes } = this.props;
    const { columnSettings, incomingHeader } = this.state;

    let filteredData = this.prepareData();

    return (
      <div className={classes.root}>
        <Helmet>
          <title>Incoming{HTML_TITLE_SUFFIX}</title>
        </Helmet>
        <main className="Main">
          <div className="Main__Content">
            <div className="Title__Content">
              <div className="Title">INCOMING</div>
            </div>
            <div className="Table__Content">
              <div className="Info__Content">
                <div className="Description">Incoming Table View</div>
                <div className="Buttons_Content">
                  {filteredData.length > 0 && (
                    <CSVLink
                      data={this.prepareCSV(filteredData)}
                      filename={`Dashboard Incoming Export ${format(new Date(), 'MM-dd-y')}.csv`}
                      className={'Button export-link'}
                      target="_blank"
                    >
                      <ArchiveIcon className="Icon" />
                      <span className="Label">Export CSV</span>
                    </CSVLink>
                  )}
                </div>
              </div>
              <div className="Table">
                <div className="inventory-table-wrapper">
                  <IncomingTable headers={incomingHeader} data={filteredData} />
                </div>
              </div>
            </div>
          </div>
        </main>

        <Modal
          title={'Select Column to View'}
          subtitle={'Choose which column to show in data table'}
          show={columnSettings}
          onClose={this.closeToggle}
          onSubmit={this.onSubmit}
        >
          <form className="form-modal" onSubmit={this.onSubmit}>
            {incomingHeader.map((header, index) => {
              return (
                <label className="row" key={index}>
                  <div className="field">
                    <input
                      type="checkbox"
                      defaultChecked={header.onTable}
                      onChange={() => this.handleChangeTable(header)}
                    />
                  </div>
                  <span className="text">{header.label}</span>
                </label>
              );
            })}
          </form>
        </Modal>
      </div>
    );
  }
}

Incoming.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
});

const ConnectedIncoming = connect(
  state => state,
  mapDispatchToProps
)(Incoming);

export default withStyles(styles, { withTheme: true })(ConnectedIncoming);
