import { getYear } from 'date-fns';
import _ from 'lodash';
import { compareCaseInsensitive } from 'shared/utils';

export const receiveStatus = (
  productOrder,
  shipment,
  inboundShipment
) => {
  if (inboundShipment) {
   return inboundShipment.status;
  }

  if (productOrder.closed === 1) {
    return 'received';
  }

  const year = getYear(new Date(shipment.landingDate));
  if (year <= 2021) {
    return 'received';
  }
};

export const productOrderStatus = productOrder => {
  if (productOrder === undefined) {
    return '';
  }
  return productOrder.closed === 1 ? 'Closed' : 'Open';
};

export const getECSD = (shipmentAllItems, shipment) => {
  let notWaitlist = false;

  shipmentAllItems
    .filter(r => r.shipmentId === shipment.id)
    .map(r => {
      if (r.waitlist === 0) {
        notWaitlist = true;
      }
    });

  return {
    waitlist: !notWaitlist
  };
};
