import { css, StyleSheet } from 'aphrodite';
import React from 'react';
import { black, tableLineGray } from 'shared/styles/colors';
import ReactPlaceholder from 'react-placeholder';
import "react-placeholder/lib/reactPlaceholder.css";
import { RectShape } from 'react-placeholder/lib/placeholders';
import { LOADING_IDENTIFIER } from 'shared/constants';
import { Cell as ReactTableCell, Row } from 'react-table';
import Checkbox from 'components/icons/Checkbox';
import { useDispatch } from 'react-redux';
import { editWaitlistCell } from 'redux/thunks';
import { editSKUDetails } from 'redux/thunks/ProductInventory/editSkuDetails';
interface Props {
  cell: ReactTableCell;
  row: Row;
}

export const CheckboxCell = ({
  cell: { value, column },
  row: { index, original },
}: Props) => {
  const dispatch = useDispatch();

  if (value === LOADING_IDENTIFIER) {
    return (
      <div className={css(styles.Cell, styles.CellLoading)}>
        {/* @ts-ignore */}
        <ReactPlaceholder
          showLoadingAnimation
          customPlaceholder={<RectShape color={tableLineGray} style={{ width: '80%', height: 24 }} />}
        />
      </div>
    );
  }

  return (
    <div className={css(styles.Cell)}>
      <div
        className={css(styles.checkboxContainer)}
        onClick={() => dispatch(editSKUDetails({
          rowIndex: index,
          currentValue: value,
          // @ts-ignore
          sku: original.sku,
          key: column.id,
        }))}
      >
        <Checkbox
          checked={value}
          size={16}
        />
      </div>
    </div>
  );
};

const styles = StyleSheet.create({
  Cell: {
    minHeight: 54,
    display: 'flex',
    alignItems: 'center',
  },
  CellLoading: {
    paddingLeft: 24,
    paddingRight: 24,
  },
  //
  text: {
    fontSize: 16,
    color: black,
  },
  sortIconWrapper: {
    padding: 2,
    marginTop: 4,
    cursor: 'pointer',
  },
  checkboxContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: '1',
    cursor: 'pointer',
  },
});
