import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { css, StyleSheet } from "aphrodite";
import PurchaseOrdersTable from "components/ReactTable/PurchaseOrdersTable";
import actions from "../../actions";
import { productOrderStatus } from "../../helpers/product";
import FilterButton from "components/FilterButton";

class PurchaseOrders extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filterKey: "Open",
    };

    this.mapValidHeaders = this.mapValidHeaders.bind(this);
    this.filterRowsData = this.filterRowsData.bind(this);
  }

  mapValidHeaders(headers) {
    if (headers && headers.length > 0) {
      return headers.filter((item) => item.onTable);
    }
    return headers;
  }

  filterRowsData(rowData, criteria) {
    const rows = rowData.map((row) => {
      row.status = productOrderStatus(row);
      return row;
    });
  
    return criteria === "All"
      ? rows
      : rows.filter((row) => row.status === criteria);
  }

  render() {
    const { order } = this.props;
    const headers = this.mapValidHeaders(order.tableHeaders);
    const { filterKey } = this.state;

    return (
      <div className="inventory-table-wrapper">
        <div className={css(styles.buttonsContainer)}>
          <FilterButton
            handleClick={() => this.setState({ filterKey: "Open" })}
            text={"Open"}
            isSelected={filterKey === "Open"}
          />
          <FilterButton
            handleClick={() => this.setState({ filterKey: "Closed" })}
            text={"Closed"}
            isSelected={filterKey === "Closed"}
          />
          <FilterButton
            handleClick={() => this.setState({ filterKey: "All" })}
            text={"All"}
            isSelected={filterKey === "All"}
          />
        </div>

        <PurchaseOrdersTable
          headers={headers}
          data={this.filterRowsData(order.ordersData, filterKey)}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(
  (state) => state,
  mapDispatchToProps
)(PurchaseOrders);

const styles = StyleSheet.create({
  buttonsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "12px 0",
  },
});
