import { ImmerReducer } from "immer-reducer";
import { createActionCreators, createReducerFunction } from "immer-reducer";
import { ProductInventoryState } from "types/state";


const initialState: ProductInventoryState = {
  productInventory: [],
  isFetchingProductInventory: true,
};

class ProductInventoryReducer extends ImmerReducer<ProductInventoryState> {
  setProductInventory(productInventory: any) {
    this.draftState.productInventory = productInventory;
  }

  setIsFetchingProductInventory(isFetchingProductInventory: boolean) {
    this.draftState.isFetchingProductInventory = isFetchingProductInventory;
  }

  modifyWaitlistCell({
    rowIndex,
    isWaitlist,
  }: {
    rowIndex: number;
    isWaitlist: boolean;
  }) {
    this.draftState.productInventory[rowIndex].waitlist = isWaitlist;
    this.draftState.productInventory[rowIndex].webShipDate =
      isWaitlist ? 'Waitlist' : null;
  }

  modifyWebShipDateCell({
    rowIndex,
    webShipDate,
  }: {
    rowIndex: number;
    webShipDate: Date | null;
  }) {
    this.draftState.productInventory[rowIndex].webShipDate = webShipDate;
  }

  modifyCheckboxCell({
    rowIndex,
    value,
    key,
  }: {
    rowIndex: number;
    value: boolean;
    key: string;
  }) {
    this.draftState.productInventory[rowIndex][key] = value;
  }
}

export const ProductInventoryActions = createActionCreators(ProductInventoryReducer);
const reducerFunction = createReducerFunction(ProductInventoryReducer, initialState);
export default reducerFunction;
