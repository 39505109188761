import React from "react";
import { css, StyleSheet } from "aphrodite";
import { darkGold, gold, white, brightGreen, lightGold } from "shared/styles/colors";
import Text, { Font } from "components/Text";
import { Icon } from "react-feather";

interface Props {
  text: string;
  isSelected: boolean;
  handleClick: () => void;
  Icon?: Icon;
}

const FilterButton = ({ text, isSelected, handleClick, Icon }: Props) => {
  return (
    <div
      className={css(
        styles.filterButtonContainer,
        isSelected && styles.filterButtonContainerActive
      )}
      onClick={handleClick}
    >
      {Icon && (
        <div className={css(styles.iconContainer)}>
          <Icon color={isSelected ? brightGreen : gold} size={15} />
        </div>
      )}
      <Text
        font={Font.SourceSansProSemibold}
        style={
          isSelected ? styles.filterButtonTextActive : styles.filterButtonText
        }
      >
        {text}
      </Text>
    </div>
  );
};

export default FilterButton;

const styles = StyleSheet.create({
  filterButtonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 16,
    padding: "0 16px",
    borderRadius: 20,
    height: 32,
    backgroundColor: white,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: gold,
    transition: "100ms backgroundColor",
    cursor: "pointer",
  },
  filterButtonContainerActive: {
    backgroundColor: lightGold,
    borderWidth: 2,
  },
  filterButtonText: {
    fontSize: 16,
    color: darkGold,
    transition: "100ms",
  },
  filterButtonTextActive: {
    fontSize: 16,
    transition: "100ms",
    color: darkGold,
  },
  iconContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 8.5,
  },
});
