import { sortByDate, filterCalendarEqualTo, filterWOH, filterWaitlist, filterInventoryWebShipDate, locationAgnosticSearch, filterShopifyStatus, filterMinMax, filterReserved, filterDiscontinued } from 'components/NewReactTable/tableUtils';
import { CalendarColumnFilter, SelectColumnFilter, MinMaxColumnFilter } from 'components/NewReactTable/Table';
import { ShipheroBackorderCell, ShopifyInventoryCell, WaitlistCell, WebShipDateCell } from 'components/NewReactTable/Cells';
import React from 'react';
import { CheckboxCell } from 'components/NewReactTable/Cells/CheckboxCell';

export const columnsData = [
  {
    Header: 'SKU',
    accessor: 'sku',
    width: 164,
  },
  {
    Header: 'Shopify Title',
    accessor: 'shopifyTitle',
    width: 365,
    filter: locationAgnosticSearch,
  },
  {
    Header: 'Shopify Inventory',
    accessor: 'shopifyInventory',
    width: 160,
    Cell: ShopifyInventoryCell,
    filter: filterMinMax,
    Filter: MinMaxColumnFilter,
  },
  {
    Header: 'Warehouse Inventory',
    accessor: 'shipheroInventory',
    width: 160,
    filter: filterMinMax,
    Filter: MinMaxColumnFilter,
  },
  {
    Header: 'Warehouse Backorder',
    accessor: 'shipheroBackorder',
    width: 160,
    Cell: ShipheroBackorderCell,
    filter: filterMinMax,
    Filter: MinMaxColumnFilter,
  },
  {
    Header: 'Current Shipment Left to Pre-sell',
    accessor: 'currentShipmentLeftToPreSell',
    width: 160,
    filter: filterMinMax,
    Filter: MinMaxColumnFilter,
  },
  {
    Header: 'Earliest Customer Ship Date',
    accessor: 'ecsd',
    Filter: (props) => <CalendarColumnFilter {...props} />,
    filter: filterCalendarEqualTo,
    sortType: sortByDate,
    width: 180,
  },
  {
    Header: 'Web Ship Date',
    accessor: 'webShipDate',
    Filter: (props) => (
      <SelectColumnFilter
        selectOptions={[
          'Show All',
          'By Date',
          'Not Null',
          'Only Null',
        ]}
        defaultOption="Show All"
        withCalendarForSelectedOption="By Date"
        {...props} />
    ),
    filter: filterInventoryWebShipDate,
    width: 200,
    Cell: WebShipDateCell,
  },
  {
    Header: 'Waitlist',
    accessor: 'waitlist',
    width: 170,
    Cell: WaitlistCell,
    Filter: (props) => (
      <SelectColumnFilter
        selectOptions={[
          'Show All',
          'Waitlist',
          'Off Waitlist',
        ]}
        defaultOption="Show All"
        {...props} />
    ),
    filter: filterWaitlist,
  },
  {
    Header: 'Artisan',
    accessor: 'artisan',
    width: 265,
  },
  {
    Header: 'Reserved',
    accessor: 'isReserved',
    width: 150,
    Cell: CheckboxCell,
    Filter: (props) => (
      <SelectColumnFilter
        selectOptions={[
          'Show All',
          'Reserved',
          'Not Reserved',
        ]}
        defaultOption="Show All"
        {...props} />
    ),
    filter: filterReserved,
  },
  {
    Header: 'Discontinued',
    accessor: 'isDiscontinued',
    width: 140,
    Cell: CheckboxCell,
    Filter: (props) => (
      <SelectColumnFilter
        selectOptions={[
          'Show All',
          'Discontinued',
          'Active',
        ]}
        defaultOption="Show All"
        {...props} />
    ),
    filter: filterDiscontinued,
  },
  {
    Header: 'Sell Through',
    accessor: 'sellThrough',
    width: 190,
    Filter: MinMaxColumnFilter,
    filter: filterMinMax,
  },
  {
    Header: 'Shopify Weeks on Hand',
    accessor: 'shopifyWeeksOnHand',
    width: 190,
    Filter: MinMaxColumnFilter,
    filter: filterMinMax,
  },
  {
    Header: 'Warehouse Weeks on Hand',
    accessor: 'shipheroWeeksOnHand',
    width: 190,
    Filter: MinMaxColumnFilter,
    filter: filterMinMax,
  },
  {
    Header: 'Shopify Status',
    accessor: 'shopifyStatus',
    width: 190,
    Filter: (props) => (
      <SelectColumnFilter
        selectOptions={[
          'Show All',
          'Waitlist',
          'Ships [X]',
          'Other',
        ]}
        defaultOption="Show All"
        {...props} />
    ),
    filter: filterShopifyStatus,
  },
  {
    Header: 'WOH + OOO',
    accessor: 'wohOOO',
    width: 170,
    Filter: (props) => (
      <SelectColumnFilter
        selectOptions={[
          'Show All',
          'Not Null',
          'Only Null',
        ]}
        defaultOption="Show All"
        {...props} />
    ),
    filter: filterWOH,
  },
];
