import Http from 'services/Http/Http';
import { WarehouseInventoryChange } from 'types/shared';
import { Kit } from 'types/state';
import { CreateKitBody } from './types';

export * from './types';

const apiBase = '/api';
const kitsEndpoint = `${apiBase}/kits`;
const productLogEndpoint = `${apiBase}/product-log`;
const triggersEndpoint = `${apiBase}/triggers`;
const productInventoryEndpoint = `${apiBase}/product-inventory`
const incomingEndpoint = `${apiBase}/incoming`
const atRiskEndpoint = `${apiBase}/atRisk`;
const inventoryUploadEndpoint = `${apiBase}/inventory-upload`;

class Backend extends Http {
  public createKit(body: CreateKitBody) {
    return this._post({
      endpoint: kitsEndpoint,
      body,
    });
  }

  public fetchKits() {
    return this._get({
      endpoint: kitsEndpoint,
    });
  }

  public deleteKit(kitId: number) {
    return this._delete({
      endpoint: `${kitsEndpoint}/${kitId}`,
    });
  }

  public updateKit(kit: Kit, componentIdsToRemove: number[]) {
    return this._patch({
      endpoint: `${kitsEndpoint}/${kit.id}`,
      body: { kit, componentIdsToRemove },
    });
  }

  public fetchProductLog() {
    return this._get({
      endpoint: productLogEndpoint,
    });
  }

  public fetchTriggersData() {
    return this._get({
      endpoint: triggersEndpoint,
    });
  }

  public fetchProductInventory() {
    return this._get({
      endpoint: productInventoryEndpoint,
    });
  }

  public fetchIncomingData() {
    return this._get({
      endpoint: incomingEndpoint,
    });
  }

  public triggerShipheroSync() {
    return this._post({
      endpoint: `${triggersEndpoint}/shipheroTitles`,
    });
  }

  public editShipment(data: any) {
    return this._post({
      endpoint: "/shopify/shipment",
      body: data,
    });
  }

   public editSKUDetails(data: any) {
    return this._post({
      endpoint: "/shopify/sku-details",
      body: data,
    });
  }

  public trueUpWarehouseInventory() {
    return this._post({
      endpoint: "/warehouse/true-up",
    });
  }

  public fetchAtRiskData() {
    return this._get({
      endpoint: atRiskEndpoint,
    });
  }

  public getWarehouseSKUs() {
    return this._get({
      endpoint: `${inventoryUploadEndpoint}/warehouse-skus`
    });
  }
  
  public uploadWarehouseInventory(data: WarehouseInventoryChange[]) {
    return this._post({
      endpoint: inventoryUploadEndpoint,
      body: data,
    });
  }
}

export default new Backend('');
