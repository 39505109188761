import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { Helmet } from "react-helmet";

import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import CSVReader from 'react-csv-reader';
import actions from '../actions';
import Main from '../components/Main/Main';
import Modal from '../components/Modal/Modal';
import { fetchKits } from 'redux/thunks';
import { HTML_TITLE_SUFFIX } from 'shared/constants';

const drawerWidth = 260;

const styles = theme => ({
  root: {
    flexGrow: 1,
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing.unit * 7,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 9,
    },
  },
  navToolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    marginBottom: '32px',
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing.unit * 3,
  },
});

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      columnSettings: false,
    }
    this.fetchAllData = this.fetchAllData.bind(this);
    this.formatCSVHeaders = this.formatCSVHeaders.bind(this);
    this.formatCSVEntries = this.formatCSVEntries.bind(this);
    this.handleCSVUploadError = this.handleCSVUploadError.bind(this);
    this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
    this.handleDrawerClose = this.handleDrawerClose.bind(this);
    this.renderCSVUploadContainer = this.renderCSVUploadContainer.bind(this);
    this.handleToggleColumn = this.handleToggleColumn.bind(this);
    this.closeToggle = this.closeToggle.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  UNSAFE_componentWillMount() {
    const isAutenticated = localStorage.getItem('isAutenticated');
    if (!this.props.login.userLoggedIn && !isAutenticated) {
      this.props.history.push('/');
    }
  }

  componentDidMount() {
    this.fetchAllData();
  }

  fetchAllData() {
    if (this.props.shopifyProductsData.shopifyProducts.length === 0) {
      this.props.actions.fetchShopifyProductsData();
      this.props.actions.fetchShopifyMergeSKU();
    }

    if(this.props.login.userLoggedIn || localStorage.getItem('isAutenticated')) {
      this.props.actions.fetchSingleSettingsData('excludeSKU');
    }

    this.props.actions.fetchOrders();
    this.props.actions.fetchShipHeroData();
    this.props.actions.getWebShipmentDate();
    this.props.actions.fetchShipmentAllItems();
    this.props.actions.fetchShipmentAll();
    this.props.actions.fetchAllOrderDetail();
    this.props.fetchKits();

    // this.props.actions.fetchSingleSettingsData('shipheroUpdating');
  }

  formatCSVHeaders() {
    let {tableHeaders} = this.props.shopifyProductsData;
    let actionsRemoved = [];
    tableHeaders.forEach(header => {
      if (header.label !== 'Actions') {
        actionsRemoved.push(header);
      }
    });
    return actionsRemoved;
  }

  formatCSVEntries() {
    let {shopifyProducts} = this.props.shopifyProductsData;
    let formattedEntries = [...shopifyProducts].map(product => {
      if (typeof product.available === 'object') {
        product.available = ''; // set the available data to an empty string
      }
      return product;
    });
    return formattedEntries;
  }

  handleCSVUploadError() {
    console.log('an error occurred while uploading CSV file: ', ...arguments);
  }

  handleDrawerOpen() {
    this.setState({open: true});
  }

  handleDrawerClose() {
    this.setState({open: false});
  }

  renderCSVUploadContainer() {
    let shopifyResultsIn = this.props.shopifyProductsData.shopifyProducts.length > 0;
    let shipheroResultsIn = this.props.shiphero.processedData.length > 0;

    if (shopifyResultsIn && shipheroResultsIn) {
      return (
        <CSVReader
          label="Upload CSV"
          onFileLoaded={this.props.actions.parseCSVUpload}
          onError={this.handleCSVUploadError}
        />
      );
    } else {
      return (
        <div className="inventory-control-bar">
          <span className="spinning-loader"/>
          <span>
            {
              `Fetching ${shopifyResultsIn ? '' : 'Shopfiy'} ${!shopifyResultsIn && !shipheroResultsIn ? '&' : ''} ${shipheroResultsIn ? '' : 'ShipHero'} data...`
            }
          </span>
        </div>
      );
    }
  }

  closeToggle() {
    this.setState({columnSettings: false})
  }

  handleToggleColumn() {
    this.setState({columnSettings: !this.state.columnSettings})
  }

  handleChangeTable(header) {
    this.props.actions.updateTableHeader(header);
  }

  onSubmit() {
    const {shopifyProductsData} = this.props;
    this.props.actions.submitTableHeader(shopifyProductsData.tableHeaders);
    this.closeToggle();
  }

  render() {
    const {classes, shopifyProductsData} = this.props;
    const {columnSettings} = this.state;

    return (
      <div className={classes.root}>
        <Helmet>
          <title>Product Inventory {HTML_TITLE_SUFFIX}</title>
        </Helmet>
        {/* MAIN - TABLE */}
        <Main fetchAllData={this.fetchAllData} onColumnView={this.handleToggleColumn} csvExport={this.csvExport}/>
        {/* MODAL */}
        {/* CSV Export Button */}
        {/* <CSVLink
         data={this.formatCSVEntries()}
         headers={this.formatCSVHeaders()}
         filename={`inventory_data_csv_${new Date().toISOString()}.csv`}
         >
         <Button
         variant="contained"
         label="Export CSV"
         className={classNames(classes.menuButton + " dasbhoard-control-button")}
         >

         <CloudDownloadIcon />
         <span className="inventory-button-label">Export CSV</span>
         </Button>
         </CSVLink> */}
        {shopifyProductsData.tableHeaders &&
        <Modal title={"Select Column to View"} subtitle={"Choose which column to show in data table"}
               show={columnSettings} onClose={this.closeToggle} onSubmit={this.onSubmit}>
          <form className="form-modal" onSubmit={this.onSubmit}>
            {shopifyProductsData.tableHeaders.map((header, index) => {
              return (
                <label className="row" key={index}>
                  <div className="field">
                    <input type="checkbox" defaultChecked={header.onTable}
                           onChange={() => this.handleChangeTable(header)}/>
                  </div>
                  <span className="text">{header.label}</span>
                </label>
              )
            })}
          </form>
        </Modal>}
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
  fetchKits: () => dispatch(fetchKits()),
});

const ConnectedDashboard = connect(
  state => state,
  mapDispatchToProps
)(Dashboard);

export default withStyles(styles, {withTheme: true})(ConnectedDashboard);
