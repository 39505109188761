import React from "react";

import {confirmAlert} from 'react-confirm-alert'; // Import

import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import "./CustomReactTable.scss";

import Button from '@material-ui/core/Button';
import ArrowDownwardOutlined from '@material-ui/icons/ArrowDownwardOutlined';
import ArrowUpwardOutlined from '@material-ui/icons/ArrowUpwardOutlined';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import ViewIcon from '@material-ui/icons/Visibility';

import 'react-confirm-alert/src/react-confirm-alert.css';

import moment from 'moment';

const cellFormatters = {
  shipmentId: ({row}) => {
    return (
      <div className="cell sku">
        <div className="value">{row.shipmentId}</div>
      </div>
    );
  },
  shipDate: ({row}) => {
    return (
      <div className="cell cell--centered">
        <div className="value">{row.shipDate !== null ? moment(row.shipDate).format('MM/DD/YYYY') : 'N/A'}</div>
      </div>
    );
  },
  landingDate: ({row}) => {
    return (
      <div className="cell cell--centered">
        <div className="value">{row.landingDate !== null ? moment(row.landingDate).format('MM/DD/YYYY') : 'N/A'}</div>
      </div>
    );
  },
  customerShipDate: ({row}) => {
    let text = ''
    if(row.actions) {
      text = 'Waitlist'
    } else {
      if(row.customerShipDate) {
        text = moment(row.customerShipDate).format('MM/DD/YYYY')
      }
    }
    return (
      <div className="cell cell--centered">
        <div className="value">{text}</div>
      </div>
    );
  },
  shipingVendor: ({row}) => {
    return (
      <div className="cell title">
        <div className="value">{row.shipingVendor}</div>
      </div>
    );
  },
  shipingMeans: ({row}) => {
    return (
      <div className="cell title">
        <div className="value">{row.shipingMeans}</div>
      </div>
    );
  },
  available: ({row}) => {
    return (
      <div className="cell cell--centered">
        <div className="value">{row.available}</div>
      </div>
    );
  },
  expected: ({row}) => {
    return (
      <div className="cell cell--centered">
        <div className="value">{row.expected}</div>
      </div>
    );
  },
  tracking: ({row}) => {
    return (
      <div className="cell cell--centered">
        <div className="value">{row.tracking}</div>
      </div>
    );
  },
  status: ({row}) => {
    return (
      <div className="cell title">
        <div className="value">{row.status}</div>
      </div>
    );
  },
  receiveStatus: ({row}) => {
    return (
      <div className="cell title">
        <div className="value">{row.receiveStatus}</div>
      </div>
    );
  }
};

class ShipmentTable extends React.Component {
  constructor(props) {
    super(props);

    this.constructHeaderObject = this.constructHeaderObject.bind(this);
    this.handleView = this.handleView.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  handleView(id) {
    this.props.handleView(id);
  }

  handleEdit(id) {
    this.props.handleEdit(id);
  }

  handleDelete(id) {
    confirmAlert({
      title: 'Are you sure?',
      message: 'Are you sure you want to delete this shipment?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            this.props.handleDelete(id);
          }
        },
        {
          label: 'Cancel',
          onClick: () => {
            console.log('click no')
          }
        }
      ],
      customUI: ({onClose}) => {
        return (
          <div class="react-confirm-alert-body">
            <h1>Are you sure?</h1>
            Are you sure you want to delete this shipment?
            <div class="react-confirm-alert-button-group">
              <Button className={"Button button-confirmation"} onClick={() => {
                this.props.handleDelete(id);
                onClose();
              }}>Yes
              </Button>
              <Button className={"Button button-confirmation"} onClick={onClose}>Cancel</Button>
            </div>
          </div>
        )
      }
    });

  }

  constructHeaderObject(header) {
    let {label, key} = header; // Refer to /src/reducers/shopify_products_reducer.js to see list of Header objects
    let formattedHeader = {
      //Header: label,
      Header: props =>
        <div className='column'>
          <div className='label'>{label}</div>
          <div className='arrows_orders'>
            <ArrowDownwardOutlined className="Icon"/>
            <ArrowUpwardOutlined className="Icon"/>
          </div>
        </div>,
      accessor: key
    };

    if (key === 'actions') {
      formattedHeader.Cell = ({row}) => {
        return (
          <div className="icon-manage">
            <span className="icon-manage__element icon-manage__element--edit"
                  onClick={(e) => this.handleView(row.shipmentId)}><ViewIcon className="Icon"/></span>
            <span className="icon-manage__element icon-manage__element--edit"
                  onClick={(e) => this.handleEdit(row.shipmentId)}><EditIcon className="Icon"/></span>
            <span className="icon-manage__element icon-manage__element--remove"
                  onClick={(e) => this.handleDelete(row.shipmentId)}><CloseIcon className="Icon"/></span>
          </div>
        );
      };
    }

    if (key === 'shipmentId') {
      formattedHeader.sortMethod = (a, b) => {
        let aValue = a.split('-');
        let bValue = b.split('-');

        return parseInt(aValue[1]) - parseInt(bValue[1]);
      }
    }

    if (cellFormatters.hasOwnProperty(key)) {
      formattedHeader.Cell = cellFormatters[key];
    }

    return formattedHeader;
  }

  getTrProps(
    state,
    {
      row: {
        receiveStatus,
      },
    },
    instance,
  ) {
    if (receiveStatus === "Received" || receiveStatus === "Historic Close") {
      return {
        style: {
          background: '#8e8e8e'
        }
      }
    }

    if (receiveStatus === "Partially Received") {
      return {
        style: {
          background: '#cfcfcf'
        }
      }
    }

    return {};
  };

  render() {
    const {
      headers = [],
      data = []
    } = this.props;

    return (
      <div>
        <ReactTable
          data={data}
          filterable
          defaultFilterMethod={(filter, row) => String(row[filter.id].toLowerCase()).includes(filter.value.toLowerCase())}
          columns={[
            {
              Header: "Shipments for this PO",
              columns: headers.map(this.constructHeaderObject)
            }
          ]}
          pageSize={data.length}
          showPagination={false}
          className="-striped -highlight "
          getTrProps={this.getTrProps}
        />
      </div>
    )
  }
}

export default ShipmentTable;
