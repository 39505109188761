import * as types from '../types';

const INITIAL_STATE = {
  shipmentsData: [],
  shipmentAll: [],
  shipmentAllItems: [],
  shipmentItems: [],
  shipmentDetails: [],
  selectedSKU: [],
  shipmentHeaders: [
    {label: 'Shipment ID', key: 'shipmentId', onTable: true},
    {label: 'Artisan ship date', key: 'shipDate', onTable: true},
    {label: 'Estimated landing date', key: 'landingDate', onTable: true},
    {label: 'Tracking #', key: 'tracking', onTable: true},
    {label: 'Earliest customer ship date', key: 'customerShipDate', onTable: true},
    {label: 'Netsuite Status', key: 'receiveStatus', onTable: true},
    {label: 'Shipping Vendor', key: 'shipingVendor', onTable: true},
    {label: 'Shipping Means', key: 'shipingMeans', onTable: true},
    {label: '', key: 'actions', onTable: true},
  ]
};

const shipmentReducer = (state = INITIAL_STATE, action) => {
  let newState = {...state};

  switch (action.type) {
    case types.PROCESS_SHIPMENT:
      console.log('reducer', action.payload.data);
      newState.shipmentsData = action.payload.data;
      return newState;

    case types.PROCESS_SHIPMENT_DETAILS:
      newState.shipmentDetails = action.payload.data;
      return newState;

    case types.PROCESS_SHIPMENT_ITEMS:
      newState.shipmentItems = action.payload.data;
      return newState;

    case types.PROCESS_SHIPMENT_ALL_ITEMS:
      newState.shipmentAllItems = action.payload.data;
      return newState;

    case types.PROCESS_SHIPMENT_ALL:
      newState.shipmentAll = action.payload.data;
      return newState;

    case types.SELECT_SHIPMENT_SKU:
      if(typeof action.payload.data === 'object') {
        newState.selectedSKU = action.payload.data;
      } else {
        let currentSelections = [...newState.selectedSKU];
        if(currentSelections.indexOf(action.payload.data) !== -1) {
          currentSelections.splice(currentSelections.indexOf(action.payload.data), 1);
        } else {
          currentSelections.push(action.payload.data);
        }

        newState.selectedSKU = currentSelections;
      }

      return newState;

    case types.CLEAR_SHIPMENT_SKU:
      newState.selectedSKU = action.payload.data;
      return newState;

    default:
      return newState;
  }
};

export default shipmentReducer;
